import { IconButton, STYLE_DARK } from '@eventbrite/eds-icon-button';
import { HeartChunky, HeartFillChunky } from '@eventbrite/eds-iconography';
import { gettext } from '@eventbrite/i18n';
import { logEvent } from '@eventbrite/statsig';
import React, { lazy, Suspense } from 'react';
import { HEAP_EVENT_LIKE_CLICK } from '../../constants/analytics';
import { useEventCardTrackingContext } from '../EventCardTracking';
import { LikeButtonWithText } from '../LikeButtonWithText';
import { useSaveEvent } from './hooks';

const LoggedOutRedirect = lazy(() => import('./LoggedOutRedirect'));

export interface EventCardSaveActionProps {
    isAuthenticated: boolean;
    savedByYou: boolean;
    id: string;
    eventName: string;
    imageUrl?: string;
    onClick?: (isSaved: boolean, eventId: string) => void;
    actionStyle?: string;
    isShowLikesExperimentEnabled?: boolean;
    bookmarksCount?: number;
}

export const EventCardSaveAction: React.FunctionComponent<
    EventCardSaveActionProps
> = (props) => {
    const { savedByYou, id, bookmarksCount, isShowLikesExperimentEnabled } =
        props;
    const { statsigLocationString } = useEventCardTrackingContext();
    const [renderLoggedOutRedirect, setRenderLoggedOutRedirect] =
        React.useState(false);

    const { isSaved, handleSaveEvent } = useSaveEvent({
        initialSaved: savedByYou,
        eventId: props.id,
        isAuthenticated: props.isAuthenticated,
        onCompleted: (saved: boolean) => {
            props.onClick?.(saved, props.id);
        },
    });

    const handleSaveClick = () => {
        //add statsig tracking here. pull it into central tracking?
        if (statsigLocationString) {
            logEvent(`${statsigLocationString} - ${HEAP_EVENT_LIKE_CLICK}`);
        }

        if (props.isAuthenticated) {
            handleSaveEvent({ savedByYou: isSaved, eventId: props.id });
        } else {
            setRenderLoggedOutRedirect(true);
        }
    };

    function formatNumber(num: number | undefined): string | null {
        if (!num) {
            return null;
        }
        if (num <= 999) {
            return `${num}`;
        }
        return `${(num / 1000).toFixed(1)}k`.replace('.0', '');
    }

    const displayAmountOfLikes = formatNumber(bookmarksCount);

    return (
        <>
            {isShowLikesExperimentEnabled && displayAmountOfLikes != null ? (
                <LikeButtonWithText
                    title={gettext('Save this event: %(eventName)s', {
                        eventName: props.eventName,
                    })}
                    iconType={isSaved ? <HeartFillChunky /> : <HeartChunky />}
                    onClick={handleSaveClick}
                    aria-pressed={isSaved}
                    iconColor={isSaved ? 'ui-orange--hover' : 'grey-700'}
                    style={props.actionStyle ? props.actionStyle : STYLE_DARK}
                    data-event-id={id}
                    text={displayAmountOfLikes}
                    data-testid="like-with-text"
                ></LikeButtonWithText>
            ) : (
                <IconButton
                    title={gettext('Save this event: %(eventName)s', {
                        eventName: props.eventName,
                    })}
                    iconType={isSaved ? <HeartFillChunky /> : <HeartChunky />}
                    onClick={handleSaveClick}
                    aria-pressed={isSaved}
                    iconColor={isSaved ? 'ui-orange--hover' : 'grey-700'}
                    style={props.actionStyle ? props.actionStyle : STYLE_DARK}
                    data-event-id={id}
                />
            )}
            {renderLoggedOutRedirect && (
                <Suspense fallback={<div />}>
                    <LoggedOutRedirect
                        isShown={renderLoggedOutRedirect}
                        eventId={props.id}
                        onClose={() => setRenderLoggedOutRedirect(false)}
                        eventName={props.eventName}
                        imageUrl={props.imageUrl}
                    />
                </Suspense>
            )}
        </>
    );
};
