import {
    EventCardImage,
    EventCardLink,
    EventCardLinkProps,
    EventImageProps,
    EventImageTokens,
    FormattedEvent,
    getCarouselWidget,
    VERTICAL_EVENT_CARD_IMAGE_CONFIG,
} from '@eventbrite/event-renderer';
import React from 'react';
import { Carousel, CarouselProps } from './Carousel';
import styles from './EventCardCarousel.module.scss';

export type EventCardCarouselProps = React.PropsWithoutRef<{
    event: FormattedEvent;
    isLazyImage?: EventImageProps['loading'];
    isCardFocused?: Boolean;
    linkProps: EventCardLinkProps;
    tokens?: EventImageTokens;
}> &
    Pick<CarouselProps, 'hasAutoplay' | 'autoplayOptions'>;

const carouselOptions = {
    loop: true,
};

export function EventCardCarousel({
    event,
    tokens,
    isLazyImage,
    isCardFocused,
    linkProps,
    ...props
}: EventCardCarouselProps) {
    const images = getCarouselWidget(event);

    if (!images) {
        return null;
    }

    return (
        <Carousel
            className={styles.wrapper}
            style={tokens}
            isCardFocused={isCardFocused}
            options={carouselOptions}
            {...props}
        >
            {images.data.slides.map((slide, index) => (
                <EventCardLink {...linkProps} key={slide.image.id}>
                    <EventCardImage
                        name={`${event.name}-${index}`}
                        loading={isLazyImage ? 'lazy' : 'eager'}
                        src={slide.image.url}
                        tokens={{
                            ...VERTICAL_EVENT_CARD_IMAGE_CONFIG,
                            '--image-background-color': event.edgeColor,
                        }}
                        {...props}
                    />
                </EventCardLink>
            ))}
        </Carousel>
    );
}
