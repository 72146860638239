import { gettext } from '@eventbrite/i18n';
import { Button, Typography } from '@eventbrite/marmalade';
import React from 'react';
import { WithTestId } from '../utils/types';
import styles from './ReportAdModalCommon.module.scss';

type ReportAdInitialDisclaimerProps = WithTestId & {
    onNextStep: () => void;
};

export function ReportAdInitialDisclaimer({
    onNextStep,
    ...props
}: ReportAdInitialDisclaimerProps) {
    return (
        <div className={styles.mainContainer} {...props}>
            <section className={styles.messageContainer}>
                <Typography>
                    <span
                        // eslint-disable-next-line react/no-danger
                        dangerouslySetInnerHTML={{
                            __html: gettext(
                                "If this ad seems like it violates our <a href=%(href)s target='_blank' rel='nofollow noopener'><strong>Ad guidelines</strong></a> for prohibited content, report it, and we'll take a look.",
                                {
                                    href: 'https://www.eventbrite.com/l/ad-content-guidelines/',
                                },
                            ).toString(),
                        }}
                    />
                </Typography>
                <Typography>
                    {gettext(
                        'If someone is in danger because of an event listing, contact your local law enforcement agency for help.',
                    )}
                </Typography>
            </section>
            <footer className={styles.reportAdFooter}>
                <Button variant="primary" onClick={onNextStep}>
                    {gettext('Start report')}
                </Button>
            </footer>
        </div>
    );
}
