import React from 'react';
import { FormattedEvent } from '../../types';
import {
    useTrackImpression,
    useTrackInteraction,
    useTrackMount,
} from './hooks';
import { TrackInteractionCallback } from './types';
import {
    formatCategoryFromTags,
    formatPaidStatus,
    formatVenueToCityRegion,
} from './utils';

export interface EventCardTrackingContext {
    trackInteraction?: TrackInteractionCallback;
    statsigLocationString?: string;
    heapDataAttributes?: {
        paidStatus: string;
        eventId: string;
        category: string | null;
        locationSlug: string | null;
        eventBucketLabel?: string;
    };
}

export const EventCardTrackingContext =
    React.createContext<EventCardTrackingContext>({});

export const useEventCardTrackingContext = () => {
    const value = React.useContext(EventCardTrackingContext);

    if (value === undefined) {
        console.warn(
            'You are using a link or other interactable EventCard element without including EventCardTracking in your content. This can lead to missed analytics and it is highly recommended to include.',
        );
    }

    return value;
};

export interface EventCardTrackingProps {
    event: FormattedEvent;
    statsigLocationString?: string;
    statsigMetadata?: Record<string, string>;
    eventBucketLabel?: string;
}

export const EventCardTracking: React.FunctionComponent<
    EventCardTrackingProps
> = (props) => {
    const { event, statsigLocationString, eventBucketLabel, statsigMetadata } =
        props;
    const node = React.useRef<HTMLDivElement>(null);

    const { isFree, venue, isOnlineEvent, tags, id: eventId } = event;

    const heapDataAttributes = React.useMemo(() => {
        const paidStatus = formatPaidStatus(isFree);
        const locationSlug = formatVenueToCityRegion({ venue, isOnlineEvent });
        const category = formatCategoryFromTags(tags);

        return {
            paidStatus,
            locationSlug,
            category,
            eventId,
            eventBucketLabel,
        };
    }, [isFree, isOnlineEvent, tags, eventId, venue, eventBucketLabel]);

    const trackInteraction = useTrackInteraction(
        node,
        event,
        statsigLocationString,
        heapDataAttributes,
        statsigMetadata,
    );

    useTrackImpression(node, trackInteraction);
    useTrackMount(trackInteraction);

    const stableContextValue = React.useMemo(() => {
        return { trackInteraction, statsigLocationString, heapDataAttributes };
    }, [trackInteraction, statsigLocationString, heapDataAttributes]);

    return (
        <EventCardTrackingContext.Provider value={stableContextValue}>
            <div
                ref={node}
                data-testid="event-card-tracking-layer"
                style={{
                    position: 'absolute',
                    top: '0px',
                    left: '0px',
                    height: '100%',
                    pointerEvents: 'none',
                    width: '100%',
                }}
            />
            {props.children}
        </EventCardTrackingContext.Provider>
    );
};
