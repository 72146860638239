import {
    EventCardCarousel,
    EventCardCarouselProps,
} from '@eventbrite/ads-event-card-extra';
import {
    EventCardImage,
    EventCardLink,
    EventCardLinkProps,
    EventImageProps,
    VERTICAL_EVENT_CARD_IMAGE_CONFIG,
} from '@eventbrite/event-renderer';
import React from 'react';

export type EventCardMultiImageProps = EventCardCarouselProps & {
    isLazyImage?: EventImageProps['loading'];
    isCardFocused: Boolean;
    linkProps: EventCardLinkProps;
    hasMultiImageEnabled?: boolean;
};

const autoplayOptions = {
    delay: 2000,
    playOnInit: false,
};

export function EventCardMultiImage({
    event,
    isLazyImage,
    linkProps,
    hasMultiImageEnabled,
    isCardFocused,
    ...props
}: EventCardMultiImageProps) {
    if (hasMultiImageEnabled) {
        return (
            <EventCardCarousel
                event={event}
                isLazyImage={isLazyImage}
                isCardFocused={isCardFocused}
                linkProps={linkProps}
                data-testid="event-card-carousel"
                autoplayOptions={autoplayOptions}
                {...props}
            />
        );
    } else {
        return (
            <EventCardLink {...linkProps}>
                <EventCardImage
                    name={event.name}
                    loading={isLazyImage ? 'lazy' : 'eager'}
                    src={event.imageUrl}
                    tokens={{
                        ...VERTICAL_EVENT_CARD_IMAGE_CONFIG,
                        '--image-background-color': event.edgeColor,
                    }}
                    {...props}
                />
            </EventCardLink>
        );
    }
}
