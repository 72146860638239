import classNames from 'classnames';
import Autoplay, { AutoplayOptionsType } from 'embla-carousel-autoplay';
import useEmblaCarousel from 'embla-carousel-react';
import { EmblaOptionsType } from 'embla-carousel/components/Options';

import React, { useCallback, useEffect, useRef, useState } from 'react';
import styles from './Carousel.module.scss';

const MAX_FIXED_DOTS = 3;

export type CarouselProps = React.PropsWithChildren<{
    className?: string;
    style?: any;
    hasAutoplay?: boolean;
    autoplayOptions?: AutoplayOptionsType;
    options?: EmblaOptionsType;
    clickableDots?: Boolean;
    isCardFocused?: Boolean;
}>;
export function Carousel({
    className,
    autoplayOptions,
    hasAutoplay = !!autoplayOptions,
    options,
    clickableDots,
    isCardFocused,
    ...props
}: CarouselProps) {
    const emblaPlugins = hasAutoplay ? [Autoplay(autoplayOptions)] : [];
    const [carouselRef, carouselApi] = useEmblaCarousel(options, emblaPlugins);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const slides = React.Children.toArray(props.children);
    const autoplay = carouselApi?.plugins()?.autoplay;
    const blockInit = useRef(0);
    const blockEnd = useRef(MAX_FIXED_DOTS - 1);

    const scrollTo = useCallback(
        (index: number) => {
            if (!carouselApi) return;
            carouselApi.scrollTo(index);
        },
        [carouselApi],
    );

    const onSelect = useCallback(() => {
        carouselApi && setSelectedIndex(carouselApi.selectedScrollSnap());
    }, [carouselApi]);

    useEffect(() => {
        if (!carouselApi) return;

        onSelect();
        carouselApi.on('reInit', onSelect);
        carouselApi.on('select', onSelect);
    }, [carouselApi, onSelect]);

    useEffect(() => {
        if (!autoplay) return;
        isCardFocused ? autoplay.play() : autoplay.stop();
    }, [autoplay, isCardFocused]);

    const getDots = (numSlides: number) => {
        const dotsArray = new Array<String>(numSlides).fill(styles.dotHidden);
        updateBlock(numSlides);
        drawBlock(dotsArray);
        drawNeighbors(dotsArray, numSlides);

        return dotsArray;
    };

    const updateBlock = (numSlides: number) => {
        if (selectedIndex === 0) {
            blockInit.current = 0;
            blockEnd.current =
                numSlides < MAX_FIXED_DOTS ? numSlides - 1 : MAX_FIXED_DOTS - 1;
        }
        if (selectedIndex < blockInit.current) {
            blockInit.current--;
            blockEnd.current--;
        }
        if (selectedIndex > blockEnd.current) {
            blockInit.current++;
            blockEnd.current++;
        }
    };

    const drawBlock = (dots: String[]) => {
        for (
            let index = blockInit.current;
            index <= blockEnd.current;
            index++
        ) {
            dots[index] = styles.dot;
        }
    };

    const drawNeighbors = (dots: String[], numSlides: number) => {
        if (blockInit.current - 1 >= 0) {
            dots[blockInit.current - 1] = styles.dotMediumSize;
        }
        if (blockInit.current - 2 >= 0) {
            dots[blockInit.current - 2] = styles.dotSmallSize;
        }
        if (blockEnd.current + 1 < numSlides) {
            dots[blockEnd.current + 1] = styles.dotMediumSize;
        }
        if (blockEnd.current + 2 < numSlides) {
            dots[blockEnd.current + 2] = styles.dotSmallSize;
        }
    };

    return (
        <div className={classNames(styles.carousel, className)} {...props}>
            <div className={styles.viewport} ref={carouselRef}>
                <div className={styles.container}>
                    {slides.map((slide, index) => (
                        <div className={styles.slide} key={`carousel-${index}`}>
                            {slide}
                        </div>
                    ))}
                </div>
            </div>

            <div className={styles.dots}>
                {getDots(slides.length).map((dotClass, index) => (
                    <button
                        key={index}
                        onClick={() => clickableDots && scrollTo(index)}
                        aria-label={`See slide number ${index + 1}`}
                        className={classNames(
                            styles.dot,
                            index === selectedIndex && styles.dotSelected,
                            dotClass,
                        )}
                    />
                ))}
            </div>
        </div>
    );
}
