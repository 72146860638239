import { GenericLazyString } from '@eventbrite/i18n';
import classNames from 'classnames';
import React, { PureComponent, ReactNode } from 'react';

import { Button } from '@eventbrite/eds-button';
import { Icon } from '@eventbrite/eds-icon';

import { STYLE_NONE as BUTTON_STYLE_NONE } from '@eventbrite/eds-button';
import { getAdditionalProps } from '@eventbrite/eds-utils';
import { translationPropType } from '@eventbrite/i18n';
import { Typography } from '@eventbrite/marmalade';
import PropTypes from 'prop-types';
import {
    ICON_PROP_TYPE,
    STYLE_DARK,
    STYLE_NEUTRAL,
    STYLE_PROP_TYPE,
} from './constants';

import './likeButtonWithText.scss';

export interface LikeButtonWithTextProps {
    title: GenericLazyString;
    style?: string;
    iconType?: ReactNode;
    isActive?: boolean;
    isDisabled?: boolean;
    isOverImage?: boolean;
    onClick?: any;
    iconColor?: string;
    spanAttrs?: any;
    text: string;
}

export default class LikeButtonWithText extends PureComponent<LikeButtonWithTextProps> {
    static propTypes = {
        title: translationPropType.isRequired,
        style: STYLE_PROP_TYPE,
        iconType: ICON_PROP_TYPE,
        isActive: PropTypes.bool,
        isDisabled: PropTypes.bool,
        isOverImage: PropTypes.bool,
        onClick: PropTypes.func,
        iconColor: PropTypes.string,
        spanAttrs: PropTypes.object,
    };

    static defaultProps = {
        style: STYLE_NEUTRAL,
        isActive: false,
        isDisabled: false,
        isOverImage: false,
    };
    render() {
        const {
            style,
            title,
            isActive,
            isDisabled,
            isOverImage,
            iconType,
            onClick,
            iconColor,
            spanAttrs,
            text,
        } = this.props;

        const extraAttrs = getAdditionalProps(this);

        let buttonStyle = style;

        if (buttonStyle === STYLE_DARK) {
            buttonStyle = STYLE_NEUTRAL;
        }

        const iconButtonClasses = classNames(
            'eds-icon-button',
            'eds-icon-button--labeled',
            {
                'eds-icon-button--active': isActive,
                'eds-icon-button--disabled': isDisabled,
                'eds-icon-button--over-image': isOverImage,
                [`eds-icon-button--${buttonStyle}`]: buttonStyle,
            },
        );

        return (
            <span
                className={iconButtonClasses}
                data-spec="icon-button"
                {...spanAttrs}
            >
                <Button
                    style={BUTTON_STYLE_NONE}
                    onClick={onClick}
                    disabled={isDisabled}
                    {...extraAttrs}
                >
                    <div className="eds-icon-button--content">
                        <Icon
                            type={iconType}
                            title={title}
                            isBlockLevel={true}
                            color={iconColor}
                        />
                        <Typography
                            align="center"
                            variant="body-md"
                            color="neutral-700"
                        >
                            {text}
                        </Typography>
                    </div>
                </Button>
            </span>
        );
    }
}
