import logger from '@eventbrite/client-logger';
import { sdkRequest, setWindowLocation } from '@eventbrite/http';
import Cookies from 'cookies-js';
import { formatUrl } from 'url-lib';
import { EVENT_COOKIE_ID } from './constants';

const saveEventUrl = '/api/v3/destination/save/';
const unsaveEventUrl = '/api/v3/destination/unsave/';

const _saveOrUnsaveEvent = async (url: string, eventId: string) =>
    await sdkRequest(url, {
        method: 'POST',
        body: JSON.stringify({
            event_ids: [`${eventId}`],
        }),
    });

export const loggedOutSaveRedirect = (eventId: string) => {
    const pathname = `${window.location.pathname}${window.location.search}`;
    // save the cookie with organizer information
    Cookies.set(EVENT_COOKIE_ID, eventId, {
        path: '/',
        secure: true,
        expires: 300,
    });

    // then redirect to signin page
    setWindowLocation(
        formatUrl('/signin/', {
            referrer: formatUrl(pathname),
        }),
    );

    return Promise.resolve();
};

/**
 *
 * @param object
 *
 * Keys:
 *
 * `eventId`: the event to save
 *
 * `isAuthenticated`: if user is logged in
 *
 * `savedByYou`: the current save status of the event
 *
 * returns `{id, savedByYou}`
 */
export const saveEvent = async ({
    eventId,
    savedByYou,
}: {
    eventId: string;
    savedByYou: boolean;
}): Promise<{ id?: string; newSaveState?: boolean } | undefined> => {
    try {
        const actionEndpoint = savedByYou ? unsaveEventUrl : saveEventUrl;
        await _saveOrUnsaveEvent(actionEndpoint, eventId);

        return { id: eventId, newSaveState: !savedByYou };
    } catch (error: any) {
        // Sometimes the backend provides events that are incorreclty
        // marked as not saved. This addresses that by simply swallowing
        // the error and allow the local state to be updated hoping that
        // the next reload will behave properly.
        if (error?.parsedError?.error === 'BOOKMARK_ALREADY_EXISTS') {
            logger.warn('Bookmark already exists:', {
                error: error.message ? error.message : error,
            });

            return { id: eventId, newSaveState: !savedByYou };
        }

        throw error;
    }
};
