import { useMutation } from '@tanstack/react-query';
import Cookies from 'cookies-js';
import { useEffect, useState } from 'react';
import { useMount } from 'react-use';
import { saveEvent } from './api';
import { EVENT_COOKIE_ID } from './constants';
/**
 * On mount, check if cookie is saved for the given Event
 * and parse.
 *
 * @param handleSave
 * @param eventId
 * @param isAuthenticated
 */
export const useShouldSaveFromLoggedOutRedirect = (
    handleSave: Function,
    eventId: string,
    isAuthenticated: boolean,
) => {
    return useMount(() => {
        const eventIdFromCookie = Cookies.get(EVENT_COOKIE_ID);

        if (
            eventIdFromCookie &&
            eventIdFromCookie === eventId &&
            isAuthenticated
        ) {
            Cookies.expire(EVENT_COOKIE_ID);

            return handleSave({
                eventId,
                isAuthenticated,
                savedByYou: false,
            });
        }
    });
};

/**
 * @param setSaved Stateful callback to manage local state of the eventcard
 * @param onCompleted Callback on successful API call
 * @param eventId For use in logged out redirect save flow
 * @param isAuthenticated For use in logged out redirect save flow
 *
 * React query mutation hook which updates the event local state as we don;t
 * currently have access to the events via the querycache.
 *
 * Leverages the patterns showcased here with the useMutation hook to
 * handle optimistcally updating the response:
 * https://react-query.tanstack.com/docs/guides/optimistic-updates
 */
export const useSaveEvent = ({
    initialSaved,
    onCompleted,
    eventId,
    isAuthenticated,
}: {
    initialSaved: boolean;
    onCompleted: Function;
    eventId: string;
    isAuthenticated: boolean;
}) => {
    const [isSaved, setSaved] = useState(!!initialSaved);

    useEffect(() => {
        setSaved(initialSaved);
    }, [initialSaved]);

    /**
     * ReactQuery hook in use here to alleviate error/success/etc
     * states and simplify.
     */
    const { mutateAsync: handleSaveEvent } = useMutation(saveEvent, {
        onMutate: ({ savedByYou }: { savedByYou: boolean }) => {
            setSaved(!savedByYou);
            return { previousState: savedByYou };
        },
        onError: (err: Error, _data, context) => {
            setSaved(!!context?.previousState);
            throw err;
        },
        onSuccess: (data) => {
            return onCompleted(data?.newSaveState);
        },
    });

    /**
     * Dropping here to consolidate save
     * related logic.
     */
    useShouldSaveFromLoggedOutRedirect(
        handleSaveEvent,
        eventId,
        isAuthenticated,
    );

    return { handleSaveEvent, isSaved };
};
