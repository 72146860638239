import { NestedActionContainer } from '@eventbrite/marmalade';
import classNames from 'classnames';
import React from 'react';
import '../EventCardShared.scss';
import './VerticalEventCard.scss';

interface VerticalEventCardProps {
    isRaised?: boolean;
    actionVisibility?: 'hidden' | 'visible';
    fullClick?: boolean;
}

const ELEVATION =
    '0px 2px 8px rgba(30, 10, 60, 0.06), 0px 4px 12px rgba(30, 10, 60, 0.08)';

export const VerticalEventCard: React.FunctionComponent<
    VerticalEventCardProps
> = (props) => {
    const elevation = props.isRaised ? ELEVATION : undefined;

    const verticalEventCardClasses = classNames(
        'event-card event-card__vertical',
        {
            'vertical-event-card__action-visibility':
                props.actionVisibility === 'hidden',
            'event-card__vertical__action-visibility--raised':
                props.actionVisibility === 'hidden' && props.isRaised,
            'event-card__full-card-click': props.fullClick,
        },
    );

    return (
        <NestedActionContainer
            tokens={{
                '--ContainerElevation': elevation,
            }}
            className={verticalEventCardClasses}
        >
            {props.children}
        </NestedActionContainer>
    );
};
