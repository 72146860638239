import { FormattedEvent, isPromoCodeActive } from '@eventbrite/event-renderer';

export type DiscoverEventCardAvailableSignals =
    | 'urgencySignals'
    | 'discountCode'
    | 'BOGOLabel';

export function getEventCardSignalsVisibility({
    event,
    withLimitedVisibility,
}: {
    event: FormattedEvent;
    withLimitedVisibility: boolean;
}) {
    if (!withLimitedVisibility) {
        return {
            showUrgencySignals: true,
            showDiscountCode: true,
            showBOGOLabel: true,
        };
    }

    if (event.specialDiscounts?.hasBogoTickets) {
        return {
            showUrgencySignals: false,
            showDiscountCode: false,
            showBOGOLabel: true,
        };
    }

    if (isPromoCodeActive(event.openDiscount)) {
        return {
            showUrgencySignals: false,
            showDiscountCode: true,
            showBOGOLabel: false,
        };
    }

    return {
        showUrgencySignals: true,
        showDiscountCode: false,
        showBOGOLabel: false,
    };
}
