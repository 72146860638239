import { Icon } from '@eventbrite/eds-icon';
import { UserChunky } from '@eventbrite/eds-iconography';
import { gettext } from '@eventbrite/i18n';
import { Typography, TypographyVariant } from '@eventbrite/marmalade';
import React from 'react';

/**
 * @name getFollowSignalDisplayValue
 * @param {num} value numeric signal value
 * @returns formatted string representation of numeric signal param
 */

export const getFollowSignalDisplayValue = (value: number) => {
    if (value < 1000) {
        return `${value}`;
    }

    //returns: 2.8k
    return `${(value / 1000).toFixed(1)}k`.replace('.0', '');
};

interface EventCardOrganizerProps {
    organizerFollowerCount?: number;
    organizerName?: string;
    variantStyle?: TypographyVariant;
    className?: string;
}

export const EventCardOrganizer = ({
    organizerFollowerCount,
    organizerName,
    variantStyle = 'body-sm-bold',
    className = 'eds-text-color--ui-600',
}: EventCardOrganizerProps) => {
    if (organizerName && organizerFollowerCount === undefined) {
        return (
            <Typography variant={variantStyle} className={className}>
                {organizerName}
            </Typography>
        );
    }

    if (
        !organizerName ||
        typeof organizerFollowerCount !== 'number' ||
        organizerFollowerCount < 10
    ) {
        return null;
    }

    const signalDisplayValue = getFollowSignalDisplayValue(
        organizerFollowerCount,
    );

    const content =
        organizerFollowerCount > 1
            ? `${signalDisplayValue} ${gettext('followers')}`
            : `${signalDisplayValue} ${gettext('follower')}`;

    return (
        <div>
            <Typography variant={variantStyle}>{organizerName}</Typography>
            <div>
                <Icon type={<UserChunky />} size="xsmall" />
                <Typography as="span" variant={variantStyle}>
                    {content}
                </Typography>
            </div>
        </div>
    );
};
