import { Typography, TypographyProps } from '@eventbrite/marmalade';
import React from 'react';

interface EventCardTitleProps extends Omit<TypographyProps, 'children'> {
    title: string;
}

export const EventCardTitle: React.FunctionComponent<EventCardTitleProps> = (
    props,
) => {
    const { title, ...typographyProps } = props;

    return (
        <Typography variant="body-lg" as="h3" {...typographyProps}>
            {title}
        </Typography>
    );
};
