import { track } from '@eventbrite/datalayer-library';
import { useCallback } from 'react';
import { useEventCardTrackingContext } from '../EventCardTracking';

export const HEAP_OPEN_UPSELL_MODAL = 'Ads Entry point - Click - Open modal';
export const HEAP_CLICK_ADS_LINK =
    'Ads Entry point - Click - Ads information link';

export function usePromotedUpsellTracking() {
    const { heapDataAttributes, statsigLocationString } =
        useEventCardTrackingContext();

    const emitHeapEvent = useCallback(
        (eventName: string) =>
            track({
                eventName: eventName,
                eventData: {
                    ...heapDataAttributes,
                    interface: statsigLocationString,
                },
            }),
        [heapDataAttributes, statsigLocationString],
    );

    return {
        emitHeapEvent,
    };
}
