import {
    EventInteraction,
    EventInteractionContext,
    EventInteractions,
    PLACE_EVENT_INTERACTION,
} from '@eventbrite/ads';
import { Modal } from '@eventbrite/eds-modal';
import { FormattedEvent } from '@eventbrite/event-renderer';
import { gettext } from '@eventbrite/i18n';
import React from 'react';
import { useUnmount } from 'react-use';
import { ReportAdFormData } from './ReportAdData';
import { ReportAdForm } from './ReportAdForm';
import { ReportAdInitialDisclaimer } from './ReportAdInitialDisclaimer';
import styles from './ReportAdModal.module.scss';
import { ReportAdSuccess } from './ReportAdSuccess';

export type ReportAdModalProps = {
    event: FormattedEvent;
    handleClose: () => void;
    // FIXME: This is not ideal, since we need to pass down the whole tracking context to be able to send correctly the report ad metrics
    // We opted for sending both the report and the interaction metrics in the same event. Ideally, this should be two separate API functions
    // Separating the report and the metric interaction, metrics can behave exactly like other metrics.
    getTrackingContext: (data: EventInteraction) => EventInteractionContext;
    onSuccessClose: () => void;
};

enum ReportAdView {
    initialDisclaimer = 'initial-disclaimer',
    form = 'form',
    success = 'success',
}

export const ReportAdModal: React.FunctionComponent<ReportAdModalProps> = ({
    event,
    handleClose,
    onSuccessClose,
    getTrackingContext,
    ...props
}) => {
    const [currentView, setCurrentView] = React.useState(
        ReportAdView.initialDisclaimer,
    );
    const handleSubmit = (data: ReportAdFormData) => {
        emitReportAdEvent({ event, data, getTrackingContext });
        setCurrentView(ReportAdView.success);
    };

    useUnmount(() => {
        if (currentView === ReportAdView.success) {
            onSuccessClose();
        }
    }, []);

    const modalTitle =
        currentView === ReportAdView.success
            ? gettext('Report received')
            : gettext('Report this ad');

    return (
        <Modal
            isShown
            onClose={handleClose}
            onClickOutside={handleClose}
            title={modalTitle}
            noPadding
            dangerouslySetContainerClassName={{
                __className: styles.modal,
            }}
            isPortal
            modalHostNodeSelector={'body'}
            {...props}
        >
            {currentView === ReportAdView.initialDisclaimer && (
                <ReportAdInitialDisclaimer
                    onNextStep={() => setCurrentView(ReportAdView.form)}
                    data-testid="report-ad-initial-disclaimer"
                />
            )}
            {currentView === ReportAdView.form && (
                <ReportAdForm
                    onPreviousStep={() =>
                        setCurrentView(ReportAdView.initialDisclaimer)
                    }
                    onSubmit={handleSubmit}
                    data-testid="report-ad-form"
                />
            )}
            {currentView === ReportAdView.success && (
                <ReportAdSuccess data-testid="report-ad-success" />
            )}
        </Modal>
    );
};

function emitReportAdEvent({
    event,
    data,
    getTrackingContext,
}: Pick<ReportAdModalProps, 'event' | 'getTrackingContext'> & {
    data: ReportAdFormData;
}) {
    const action = EventInteractions.ReportAd;
    const baseContext = getTrackingContext({
        id: event.id,
        event,
        action,
    });

    window.document.dispatchEvent(
        new CustomEvent(PLACE_EVENT_INTERACTION, {
            detail: {
                ...baseContext,
                id: event.id,
                event,
                action,
                feedbackContext: data,
            },
            bubbles: true,
        }),
    );
}
