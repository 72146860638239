import { Typography, TypographyProps } from '@eventbrite/marmalade';
import React from 'react';
import { CamelVenue } from '../../types/events';

type LocationFormatFn = (venue: CamelVenue) => string;

const getFullLocationInfo: LocationFormatFn = (venue) => {
    if (venue?.name && venue?.address?.localizedAreaDisplay) {
        //ex. Eventbrite • San Francisco CA
        return `${venue?.name} \u2022 ${venue?.address?.localizedAreaDisplay}`;
    }

    return getVenueLocationInfo(venue);
};

const getVenueLocationInfo: LocationFormatFn = (venue) => {
    return venue?.name || '';
};

const getLocalLocationInfo: LocationFormatFn = (venue) => {
    return venue?.address?.localizedAreaDisplay;
};

export type LocationFormatStyles = 'full' | 'venue' | 'local';
const FORMAT_MAP = {
    full: getFullLocationInfo,
    venue: getVenueLocationInfo,
    local: getLocalLocationInfo,
};

/**
 * Extendable Utility to handle rendering location
 * information given a formatted Venue object.
 *
 * @param venue CamelVenue
 * @param style LocationFormatStyles: 'full' | 'venue' | 'local'
 * @returns
 */
export function useFormattedLocationString({
    venue,
    style,
}: {
    venue?: CamelVenue;
    style?: LocationFormatStyles;
}): string {
    const formatStyle = style ? style : 'full';

    const formatFn = React.useCallback(
        (val) => FORMAT_MAP[formatStyle]?.(val),
        [formatStyle],
    );

    const formattedLocationString = React.useMemo(() => {
        return formatFn(venue);
    }, [formatFn, venue]);

    return formattedLocationString;
}

interface EventCardLocationProps
    extends Omit<TypographyProps, 'children' | 'style'> {
    formattedLocationString?: string;
}

export const EventCardLocation: React.FunctionComponent<
    EventCardLocationProps
> = (props) => {
    const { formattedLocationString, ...typographyProps } = props;

    if (!props.formattedLocationString) {
        return null;
    }

    return (
        <Typography {...typographyProps}>{formattedLocationString}</Typography>
    );
};
