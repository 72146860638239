import { Stack, StackProps } from '@eventbrite/marmalade';
import React from 'react';
import './EventCardDetails.scss';

export interface EventCardDetailsTokens {
    '--EventCardDetailsMargin'?: string;
    '--EventCardDetailsPadding'?: string;
    '--EventCardDetailsFlexGrow'?: string;
    '--EventCardDetailsPosition'?: string;
}

interface EventCardDetailsProps extends StackProps {
    tokens?: EventCardDetailsTokens;
}

const DEFAULT_SPACE = 'spacing-xs';

export const EventCardDetails: React.FunctionComponent<
    EventCardDetailsProps
> = (props) => {
    const { space = DEFAULT_SPACE } = props;

    return (
        <section
            style={props.tokens as React.CSSProperties}
            className="event-card-details"
        >
            <Stack space={space}>{props.children}</Stack>
        </section>
    );
};
