import { gettext } from '@eventbrite/i18n';
import { Button, TextArea, TextField, Typography } from '@eventbrite/marmalade';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { WithTestId } from '../utils/types';
import {
    initialReportAdData,
    ReportAdFormData,
    ReportAdReason,
} from './ReportAdData';
import formStyles from './ReportAdForm.module.scss';
import styles from './ReportAdModalCommon.module.scss';
import { ReportAdRadioReason } from './ReportAdReason';

export type ReportAdFormProps = WithTestId & {
    onPreviousStep: () => void;
    onSubmit: (data: ReportAdFormData) => void;
};

export function ReportAdForm({
    onSubmit,
    onPreviousStep,
    ...props
}: ReportAdFormProps) {
    const { control, handleSubmit, formState } = useForm<ReportAdFormData>({
        defaultValues: initialReportAdData,
    });
    const emailErrorMessage = gettext(
        'Please enter a valid email address.',
    ).toString();

    return (
        <form
            onSubmit={handleSubmit(onSubmit)}
            className={styles.mainContainer}
            {...props}
        >
            <div className={formStyles.formContent}>
                <Typography>
                    {gettext(
                        'Help Eventbrite investigate this ad by choosing a reason for the report, and briefly explaining your concern.',
                    )}
                </Typography>
                <Typography variant="body-lg-bold">
                    {gettext('Violation reason')}
                </Typography>

                <div className={formStyles.formFields}>
                    <Controller
                        name="reason"
                        control={control}
                        rules={{
                            required: gettext(
                                'Please select a violation reason.',
                            ).toString(),
                        }}
                        render={({ field }) => (
                            <ul className={formStyles.reasonList}>
                                {reasonList.map((reason) => (
                                    <ReportAdRadioReason
                                        key={reason}
                                        reason={reason}
                                        required
                                        {...field}
                                    />
                                ))}
                            </ul>
                        )}
                    />

                    {formState.errors.reason && (
                        <Typography
                            className={formStyles.errorMessage}
                            variant="body-sm"
                        >
                            {formState.errors.reason.message}
                        </Typography>
                    )}

                    <Controller
                        name="email"
                        control={control}
                        rules={{
                            required: emailErrorMessage,
                            pattern: {
                                value: emailRegex,
                                message: emailErrorMessage,
                            },
                        }}
                        render={({ field }) => (
                            <TextField
                                id="report-an-ad-email"
                                label={gettext('Email')}
                                hasError={!!formState.errors.email}
                                helperText={formState.errors.email?.message}
                                required
                                {...field}
                            />
                        )}
                    />

                    <Controller
                        name="comment"
                        control={control}
                        render={({ field }) => (
                            <TextArea
                                id="report-an-ad-comments"
                                label={gettext('Comments (optional)')}
                                maxLength={100}
                                {...field}
                            />
                        )}
                    />
                </div>
            </div>

            <footer className={styles.reportAdFooter}>
                <Button variant="primary" type="submit">
                    {gettext('Submit report')}
                </Button>
                <Button variant="ghost" onClick={onPreviousStep}>
                    {gettext('Go back')}
                </Button>
            </footer>
        </form>
    );
}

const emailRegex = /^\S+@\S+\.\S+$/;

const reasonList = [
    ReportAdReason.adultSexual,
    ReportAdReason.harmful,
    ReportAdReason.violenceOrExtremism,
    ReportAdReason.electoralActivity,
    ReportAdReason.illegalOrRegulated,
    ReportAdReason.spamOrPhising,
    ReportAdReason.copyrightAndTrademark,
    ReportAdReason.canceledOrPostponed,
];
