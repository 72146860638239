import { Modal } from '@eventbrite/eds-modal';
import { gettext } from '@eventbrite/i18n';
import { Button, Typography } from '@eventbrite/marmalade';
import React, { useEffect } from 'react';
import promotedPreviewBackground from './assets/promoted-preview-background.svg';
import promotedPreview from './assets/promoted-preview.png';
import styles from './PromotedUpsellModal.module.scss';
import {
    HEAP_CLICK_ADS_LINK,
    HEAP_OPEN_UPSELL_MODAL,
    usePromotedUpsellTracking,
} from './PromotedUpsellTracking';

export type PromotedUpsellModalProps = {
    handleClose: () => void;
};

export const PromotedUpsellModal: React.FunctionComponent<
    PromotedUpsellModalProps
> = ({ handleClose, ...props }) => {
    const { emitHeapEvent } = usePromotedUpsellTracking();

    useEffect(() => {
        emitHeapEvent(HEAP_OPEN_UPSELL_MODAL);
    }, [emitHeapEvent]);

    const promotedUpsellData = [
        gettext(
            'Provide details about your budget, and how long you want to run your ad.',
        ),
        gettext('Choose an ad objective that matches your marketing goal.'),
        gettext(
            'Launch your ad and monitor its performance from the reporting dashboard.',
        ),
    ];

    return (
        <Modal
            isShown
            onClose={handleClose}
            onClickOutside={handleClose}
            noPadding
            dangerouslySetContainerClassName={{
                __className: styles.modalContainer,
            }}
            isPortal
            modalHostNodeSelector="body"
            {...props}
        >
            <div
                className={styles.modal}
                style={modalBackgroundStyles}
                data-testid="promoted-upsell-modal"
            >
                <header className={styles.modalHeader}>
                    <Typography variant="heading-md" color="neutral-1000">
                        {gettext('Get 30% more clicks than Facebook Ads')}
                    </Typography>
                    <Typography variant="body-lg" color="neutral-1000">
                        {gettext(
                            'Launch an ad in just a few steps, and watch your event’s popularity soar!',
                        )}
                    </Typography>
                </header>
                <ul className={styles.promotedList}>
                    {promotedUpsellData.map((reason) => (
                        <PromotedUpsellModalListItem key={reason.toString()}>
                            {reason}
                        </PromotedUpsellModalListItem>
                    ))}
                </ul>
                <footer className={styles.actions}>
                    <a
                        href="/organizations/marketing/eventbrite-ads"
                        target="_blank"
                        onClick={() => emitHeapEvent(HEAP_CLICK_ADS_LINK)}
                    >
                        <Button
                            variant="primary"
                            className={styles.upsellModalButton}
                        >
                            {gettext('Try it out')}
                        </Button>
                    </a>
                    <Button
                        className={styles.closeModalButton}
                        variant="secondary"
                        onClick={handleClose}
                    >
                        {gettext('Not now')}
                    </Button>
                </footer>
                <img
                    className={styles.promotedPreview}
                    alt="Promoted event preview"
                    src={promotedPreview}
                />
            </div>
        </Modal>
    );
};

const modalBackgroundStyles = {
    backgroundImage: `url(${promotedPreviewBackground})`,
    backgroundPosition: 'bottom right',
    backgroundRepeat: 'no-repeat',
};

function PromotedUpsellModalListItem({
    children,
}: React.PropsWithChildren<{}>) {
    return (
        <Typography
            className={styles.promotedItem}
            variant="body-md"
            color="neutral-1000"
            as={'li' as any}
        >
            {children}
        </Typography>
    );
}
