import { GenericLazyString, gettext } from '@eventbrite/i18n';
import { Radio, RadioProps, Typography } from '@eventbrite/marmalade';
import React from 'react';
import styles from './ReportAdReason.module.scss';

import { Icon } from '@eventbrite/eds-icon';
import { HelpChunky } from '@eventbrite/eds-iconography';
import { withTooltip } from '@eventbrite/eds-tooltip';

import { ControllerRenderProps } from 'react-hook-form';
import { ReportAdFormData, ReportAdReason } from './ReportAdData';

export type ReportAdReasonProps = ControllerRenderProps<
    ReportAdFormData,
    'reason'
> &
    RadioProps & {
        reason: ReportAdReason;
    };

const IconWithTooltip = withTooltip(Icon);

export const ReportAdRadioReason = React.forwardRef<
    HTMLLIElement,
    ReportAdReasonProps
>(({ reason, value, ...props }, ref) => {
    const reasonId = `report-an-ad-${reason}`;
    const tooltip = getReasonTooltip(reason);
    return (
        <li className={styles.reason} ref={ref}>
            <Radio
                id={reasonId}
                value={reason}
                checked={reason === value}
                {...props}
            />
            <label htmlFor={reasonId}>
                <Typography as="span">{getReasonLabel(reason)}</Typography>
            </label>
            {tooltip && (
                <IconWithTooltip
                    type={<HelpChunky fill="#6f7287" />}
                    size="xsmall"
                    tooltipId="tooltip-info"
                    tooltipStyle="phab"
                    wrapperComponent="span"
                    tooltipText={
                        <Typography variant="body-sm">{tooltip}</Typography>
                    }
                    tooltipArrowLocation="left"
                />
            )}
        </li>
    );
});

function getReasonTooltip(reason: ReportAdReason) {
    const tooltips: Partial<Record<ReportAdReason, GenericLazyString>> = {
        [ReportAdReason.harmful]: gettext(
            'Includes events that promote false or misleading information; unacceptable or fraudulent business practices; or harm to animals.',
        ),
        [ReportAdReason.violenceOrExtremism]: gettext(
            'Encourages violence or harassment towards others and/or oneself.',
        ),
        [ReportAdReason.electoralActivity]: gettext(
            'Promotes political figures/campaigns or advocates for social issues',
        ),
        [ReportAdReason.illegalOrRegulated]: gettext(
            'Includes the promotion of tobacco, e-cigarettes, cannabis products, alcohol, weapons, gambling, and healthcare products and services.',
        ),
    };

    return tooltips[reason];
}

function getReasonLabel(reason: ReportAdReason) {
    const labels = {
        [ReportAdReason.adultSexual]: gettext('Adult sexual content'),
        [ReportAdReason.harmful]: gettext('Harmful content'),
        [ReportAdReason.violenceOrExtremism]: gettext(
            'Violence or extremism content',
        ),
        [ReportAdReason.electoralActivity]: gettext('Electoral activity'),
        [ReportAdReason.illegalOrRegulated]: gettext(
            'Illegal or regulated content',
        ),
        [ReportAdReason.spamOrPhising]: gettext('Spam or phising'),
        [ReportAdReason.copyrightAndTrademark]: gettext(
            'Copyright and trademark',
        ),
        [ReportAdReason.canceledOrPostponed]: gettext('Canceled or postponed'),
    };

    return labels[reason];
}
