import { getExperiment, getLayer, useExperiment } from '@eventbrite/statsig';
import { useEffect, useState } from 'react';

export enum StatsigEnrollmentType {
    LAYER = 'layer',
    EXPERIMENT = 'experiment',
}

export enum GetEnrollmentStatus {
    LOADING = 'loading',
    SUCCESS = 'success',
    DISABLED = 'disabled',
}

export type GetStatsigEnrollmentResponse<T> = {
    status: GetEnrollmentStatus;
    variant: T;
};

export const ENROLLMENT_FN_MAP = {
    [StatsigEnrollmentType.EXPERIMENT]: getExperiment,
    [StatsigEnrollmentType.LAYER]: getLayer,
};

export interface IUseGetStatsigEnrollment<T> {
    name: string;
    paramName: string;
    defaultValue: T;
    enabled?: boolean;
    enrollmentType?: StatsigEnrollmentType;
}

/**
 * use `useClientStatsigEnrollment`
 *
 * **IMPORTANT**
 * Hook assumes the statsig SDK
 * has been initialized and returns the
 * expected variant. It is not listening
 * for the initialization to re-trigger
 * fetching an experiment.
 *
 * According to statsig docs, the `getExperiment` and `getLayer`
 * lookups are a synchronous operation and so should
 * have little latency overhead.
 *
 * Until mounted on the client, the hook will return
 * 'loading'. Allowing server side content to
 * render appropriately until the variant
 * is provided.
 *
 * @param name: Name of the layer or the experiment
 * @param paramName: Name of the param to retrieve the variant for
 * @returns GetStatsigEnrollmentResponse
 */
export function useGetStatsigEnrollment<T>({
    name,
    paramName,
    defaultValue,
    enabled = true,
    enrollmentType = StatsigEnrollmentType.EXPERIMENT,
}: IUseGetStatsigEnrollment<any>): GetStatsigEnrollmentResponse<T> {
    const [enrollmentVariantValue, setEnrollmentVariantValue] =
        useState<T>(defaultValue);

    const [status, setGetEnrollmentStatus] = useState<GetEnrollmentStatus>(
        !enabled ? GetEnrollmentStatus.DISABLED : GetEnrollmentStatus.LOADING,
    );

    useEffect(() => {
        if (enabled) {
            const getVariant = ENROLLMENT_FN_MAP[enrollmentType];

            const variant = getVariant(name, paramName, defaultValue);

            setEnrollmentVariantValue(variant);
            setGetEnrollmentStatus(GetEnrollmentStatus.SUCCESS);
        }
    }, [name, paramName, defaultValue, enabled, enrollmentType]);

    return {
        status,
        variant: enrollmentVariantValue,
    } as GetStatsigEnrollmentResponse<T>;
}

/**
 * **IMPORTANT**
 *
 * This hook assumes that the backend has passed along the
 * the Statsig bootstrap initialization config from the server
 * and StatsigSynchronousProvider is present in the tree.
 *
 * Recommended way to enroll Server enrolled users.
 *
 * Views that support this flow currently:
 * CategoryBrowse
 * Search
 *
 * @param param0
 * @returns
 */
export function useServerEnrolledStatsigExperiment<T>({
    name,
    paramName,
    defaultValue,
    enabled,
}: IUseGetStatsigEnrollment<T>) {
    const response = useExperiment<T>(name, paramName, defaultValue, enabled);
    const value = response.value as T;

    const result: GetStatsigEnrollmentResponse<T> = {
        status: response.isLoading
            ? GetEnrollmentStatus.LOADING
            : GetEnrollmentStatus.SUCCESS,
        variant: value,
    };

    return result;
}
