import { LoadingSkeleton } from '@eventbrite/loading-skeleton';
import React from 'react';
import './EventCardTypographyLoading.scss';

interface EventCardTypographyLoadingProps {
    variant: string | undefined;
    width: string;
}

const loadingMap = {
    'body-lg': 'loading-skeleton-lg',
    'body-lg-bold': 'loading-skeleton-lg',
    'body-md': 'loading-skeleton-md',
    'body-md-bold': 'loading-skeleton-md',
    'body-sm': 'loading-skeleton-sm',
    'body-sm-bold': 'loading-skeleton-sm',
} as { [key: string]: string };

export const EventCardTypographyLoading: React.FunctionComponent<
    EventCardTypographyLoadingProps
> = ({ variant = 'body-md', width }) => {
    const loadingClassName = loadingMap[variant];

    // return <LoadingSkeleton className={loadingClassName} width={width} />;
    return (
        <div className={loadingClassName}>
            <LoadingSkeleton height="100%" width={width} />
        </div>
    );
};
