import { gettext } from '@eventbrite/i18n';
import { Typography } from '@eventbrite/marmalade';
import React from 'react';
import { WithTestId } from '../utils/types';
import styles from './ReportAdModalCommon.module.scss';

type ReportAdSuccessProps = WithTestId;

export function ReportAdSuccess(props: ReportAdSuccessProps) {
    return (
        <div className={styles.mainContainer} {...props}>
            <section className={styles.messageContainer}>
                <Typography>
                    {gettext(
                        "We'll look into this ad, and follow up with you if necessary.",
                    )}
                </Typography>
            </section>
        </div>
    );
}
