export const HORIZONTAL_EVENT_CARD_IMAGE_CONFIG = {
    '--image-aspect-ratio': '2',
    '--image-aspect-ratio-mobile': '1',
    '--image-width': '220px',
    '--image-width-mobile': '100px',
};

export const VERTICAL_EVENT_CARD_IMAGE_CONFIG = {
    '--image-aspect-ratio': '2',
    '--image-aspect-ratio-mobile': '2',
    '--image-width': '100%',
    '--image-width-mobile': '100%',
};
