import { IconButton } from '@eventbrite/eds-icon-button';
import { ShareIosChunky } from '@eventbrite/eds-iconography';
import {
    HEAP_SHARE_EVENT_ACTION,
    HEAP_SHARE_EVENT_METHOD,
    reTrackHeapEvent,
} from '@eventbrite/eds-utils';
import { useIsMobileDevice } from '@eventbrite/feature-detection';
import { gettext } from '@eventbrite/i18n';
import { keysCamelToSnake } from '@eventbrite/transformation-utils';
import React, { lazy, Suspense } from 'react';
import { formatUrl } from 'url-lib';
import { EventCardShareOptionsProps } from '../../types/events';
import { ShareItemCallback } from './types';
import {
    getShareOptions,
    IGetShareOptions,
    shareWithSystemShare,
} from './utils';

const ShareOverlay = lazy(() => import('./ShareOverlay'));

interface BaseShareActionProps {
    onClick?: () => void;
    onShareItemClick?: ShareItemCallback;
    actionStyle?: string;
}

export const getShareActionProps = (
    options: BaseShareActionProps & IGetShareOptions,
): EventCardShareActionProps => {
    const { onClick, onShareItemClick, ...remainingOptions } = options;
    return {
        onClick,
        onShareItemClick,
        shareOptions: getShareOptions(remainingOptions),
    };
};

interface EventCardShareActionProps extends BaseShareActionProps {
    shareOptions: EventCardShareOptionsProps;
}

export const EventCardShareAction: React.FunctionComponent<
    EventCardShareActionProps
> = (props) => {
    const [renderShareBox, setRenderShareBox] = React.useState(false);
    const isMobile = useIsMobileDevice();

    async function handleShareClick() {
        props.onClick?.();
        reTrackHeapEvent({
            eventId: props?.shareOptions?.eventId,
            heapEventName: HEAP_SHARE_EVENT_ACTION,
        });

        if (isMobile) {
            shareWithSystemShare({
                eventName: props.shareOptions.eventName,
                eventUrl: formatUrl(
                    props.shareOptions.eventUrl,
                    keysCamelToSnake({
                        ...props.shareOptions.utmOptions,
                        aff: 'ebdsshcopyurl',
                    }),
                ),
                onSuccess: () => handleShareItemClick('os'),
                onFail: () => setRenderShareBox(true),
            });
        } else {
            setRenderShareBox(true);
        }
    }

    function handleShareItemClick(shareType: string) {
        reTrackHeapEvent({
            eventId: props?.shareOptions?.eventId,
            heapEventName: HEAP_SHARE_EVENT_METHOD,
            shareMethod: shareType,
        });
        props.onShareItemClick?.(shareType);
    }

    return (
        <>
            <IconButton
                iconType={<ShareIosChunky />}
                title={gettext('Share this event: %(eventName)s', {
                    eventName: props.shareOptions.eventName,
                })}
                onClick={handleShareClick}
                style={props.actionStyle}
            />
            {renderShareBox ? (
                <Suspense fallback={<div />}>
                    <ShareOverlay
                        handleShareItemClick={handleShareItemClick}
                        shareOptions={props.shareOptions}
                        onClose={() => setRenderShareBox(false)}
                    />
                </Suspense>
            ) : null}
        </>
    );
};

export default EventCardShareAction;
