import {
    SearchDesktopMapExperiment,
    useServerEnrolledStatsigExperiment,
} from '@eventbrite/discover-utils';

export const useMapExperiment = (isMobile: boolean) => {
    const mapExperiment = useServerEnrolledStatsigExperiment<string>({
        name: SearchDesktopMapExperiment.name,
        paramName: SearchDesktopMapExperiment.paramName,
        defaultValue: SearchDesktopMapExperiment.values.control,
        enabled: !isMobile,
    });

    return mapExperiment.variant === SearchDesktopMapExperiment.values.variant;
};
