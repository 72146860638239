import { GenericLazyString } from '@eventbrite/i18n';
import { Typography, TypographyProps } from '@eventbrite/marmalade';
import React from 'react';

interface EventCardDateProps
    extends Omit<TypographyProps, 'children' | 'style'> {
    formattedDateString?: GenericLazyString | string;
}

export const EventCardDate: React.FunctionComponent<EventCardDateProps> = (
    props,
) => {
    const { formattedDateString, ...typographyProps } = props;

    if (!formattedDateString) {
        return null;
    }

    return <Typography {...typographyProps}>{formattedDateString}</Typography>;
};
