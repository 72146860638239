/**
 * Calculates the position of a rect to the bottom of the windows view port
 * to determine if a drop down should drop up or down
 * @param {Object} rect Dimensions of the component that will drop down when triggered
 * @param {Object} windowSize Dimensions of the window
 * @param {Element} triggerRef A react ref for the element that triggers the expansion of the drop down
 * @summary The rect and windowSize objects come from the Reach UI library we are leveraging.
 * Documentation for Reach UI rect and WindowSize can be found at the following links:
 * https://ui.reach.tech/rect
 * https://ui.reach.tech/window-size
 */
export const calculateShouldDropUp = ({ rect, windowSize, triggerRef }) => {
    let shouldDropUp = false;

    if (rect && triggerRef) {
        const triggerRect = triggerRef.getBoundingClientRect();

        shouldDropUp = windowSize.height - triggerRect.bottom < rect.height;
    }

    return shouldDropUp;
};
