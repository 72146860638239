import logger from '@eventbrite/client-logger';
import { getDateFnsLocale, guessUserTimezone } from '@eventbrite/datetime-fns';
import { gettext } from '@eventbrite/i18n';
import { differenceInDays, formatRelative } from 'date-fns';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import React from 'react';

interface UseUserTimezoneConfig {
    defaultTimezone: string;
    enabled?: boolean;
}

export const useGuessUsersTimezone = (config: UseUserTimezoneConfig) => {
    const { enabled, defaultTimezone } = config;

    const [userTimezone, setUserTimezone] = React.useState(
        defaultTimezone || '',
    );

    React.useEffect(() => {
        if (enabled) {
            const timezone = guessUserTimezone();

            if (typeof timezone === 'string') {
                setUserTimezone(timezone);
            }
        }
    }, [enabled]);

    return enabled ? userTimezone : defaultTimezone;
};

export interface GetFormattedEventDateString {
    /**
     * Startdate in `YYYY-MM-DD` format
     */
    startDate?: string;
    /**
     * Start time in `HH:MM:SS` format
     */
    startTime: string;
    /**
     * The event's timezone
     */
    timezone: string;
    /**
     * Current locale date string should be
     * rendered into.
     */
    locale: string;
    /**
     * Number of events in this instance to signify that
     * there are additional times available to the user
     */
    repeatingInstanceCount?: number;
    /**
     * Defaults to false
     *
     * **IMPORTANT**
     *
     * Should set to `true`
     * if the Event is online
     */
    useUserTimezone?: boolean;
    /**
     * A string to seperate the date and time on en_us locale
     */
    dateTimeDelimeter?: string;
}

function getFormattedDateString(
    eventDate: Date,
    currentDate: Date,
    locale: Locale,
): string {
    const dateDelta = differenceInDays(eventDate, currentDate);

    if (dateDelta < 0) {
        return getFormattedPastDateTimeString(eventDate, locale);
    }

    const shouldUseRelativeDate = dateDelta < 6;

    if (shouldUseRelativeDate) {
        const relativeDateString = formatRelative(eventDate, currentDate, {
            locale,
        });

        if (relativeDateString.startsWith('today'))
            return relativeDateString.replace('today', 'Today');
        if (relativeDateString.startsWith('tomorrow'))
            return relativeDateString.replace('tomorrow', 'Tomorrow');
        return relativeDateString;
    }

    return new Intl.DateTimeFormat(locale.code, {
        month: 'short',
        day: 'numeric',
        weekday: 'short',
        hour: 'numeric',
        minute: 'numeric',
    }).format(eventDate);
}

function getFormattedPastDateTimeString(
    eventDate: Date,
    locale: Locale,
): string {
    return new Intl.DateTimeFormat(locale.code, {
        month: 'short',
        day: 'numeric',
        weekday: 'short',
        hour: 'numeric',
        minute: 'numeric',
    }).format(eventDate);
}

function getEventDateObjects(
    dateTimeString: string,
    eventTimezone: string,
    localTimezone: string,
    shouldLocalize: boolean,
): Date[] {
    if (shouldLocalize) {
        const resetEventDate = zonedTimeToUtc(
            new Date(dateTimeString),
            eventTimezone,
        );
        const localizedEventDate = utcToZonedTime(
            resetEventDate,
            localTimezone,
        );
        return [localizedEventDate, utcToZonedTime(new Date(), localTimezone)];
    }

    const eventDate = new Date(dateTimeString);
    const currentDate = new Date();

    return [eventDate, currentDate];
}

/**
 * Hook that replaces last comma in a absolute event date or
 * 'at' string found in a relative event date with a character that has been passed down
 * using dateTimeDelimeter
 * @param formattedDateString
 * @param dateTimeDelimeter
 * @returns
 */
export function getFormattedDateStringWithDelimeter(
    formattedDateString: string,
    dateTimeDelimeter: string,
) {
    if (formattedDateString.includes(' at ')) {
        return formattedDateString.replace(' at ', ` ${dateTimeDelimeter} `);
    }
    const calendarDateIndexOf = formattedDateString.lastIndexOf(',');
    return `${formattedDateString.substring(
        0,
        calendarDateIndexOf,
    )} ${dateTimeDelimeter} ${formattedDateString.substring(
        calendarDateIndexOf + 1,
    )} `;
}
/**
 * Hook that generates a formatted event date
 * time string.
 *
 * @param config
 * @returns
 */
export function useGetFormattedEventDateTimeString(
    config: GetFormattedEventDateString,
): string | undefined {
    const {
        startTime,
        startDate,
        repeatingInstanceCount,
        useUserTimezone,
        timezone: eventTimezone,
        locale = 'en_US',
        dateTimeDelimeter,
    } = config;

    const timezone = useGuessUsersTimezone({
        enabled: useUserTimezone && !!startDate,
        defaultTimezone: eventTimezone,
    });

    const formattedDate = React.useMemo(() => {
        if (!startDate) {
            return undefined;
        }

        try {
            //Get official locale object from date-fns lib
            const dateFnsLocale = getDateFnsLocale(locale);

            //Startdate and starttime coming from API are already
            //localized to the event's timezone. This utility returns
            //javascript date instances of that time, or localized
            //to the user's current timezone depending on value of userUserTimezone
            const [eventDate, currentDate] = getEventDateObjects(
                `${startDate}T${startTime}`,
                eventTimezone,
                timezone,
                !!useUserTimezone,
            );

            //Get a localized formatted string that shows the event
            //start time relative to current day or at an a
            //specific point in the future.
            let formattedDateString = getFormattedDateString(
                eventDate,
                currentDate,
                dateFnsLocale,
            );

            if (dateTimeDelimeter && locale === 'en_US') {
                formattedDateString = getFormattedDateStringWithDelimeter(
                    formattedDateString,
                    dateTimeDelimeter,
                );
            }

            //If we localized the date to user's timezone include
            //explicit timezone information in the date string
            if (useUserTimezone) {
                const timezoneShortName =
                    new Intl.DateTimeFormat(dateFnsLocale.code, {
                        timeZoneName: 'short',
                        timeZone: timezone,
                    })
                        .format(eventDate)
                        .split(' ')[1] || '';

                formattedDateString = `${formattedDateString} ${timezoneShortName}`;
            }

            //Modify string to include number of upcoming events in the
            //case that the event is a series
            if (repeatingInstanceCount) {
                return gettext(
                    '%(formattedDateString)s + %(repeatingInstanceCount)s more',
                    { formattedDateString, repeatingInstanceCount },
                ).toString();
            }

            return formattedDateString;
        } catch (e) {
            logger.error(e);

            return undefined;
        }
    }, [
        locale,
        timezone,
        startDate,
        startTime,
        repeatingInstanceCount,
        eventTimezone,
        useUserTimezone,
        dateTimeDelimeter,
    ]);

    return formattedDate;
}
