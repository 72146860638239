import logger from '@eventbrite/client-logger';
import { gettext } from '@eventbrite/i18n';
import React from 'react';
import { EventMaxPrice, EventMinPrice } from '../../types/events';
import { getLocalizedFormattedPriceString } from './utils';

interface UseLocalizedPriceString {
    value?: number | null;
    currency: string;
    locale: string;
}
export function useLocalizedPriceString({
    value,
    currency,
    locale,
}: UseLocalizedPriceString): string | undefined {
    const formattedNumber = React.useMemo(() => {
        if (typeof value !== 'number') {
            return undefined;
        }
        try {
            const localizedValue = getLocalizedFormattedPriceString({
                locale,
                currency,
                value: value,
            });
            return localizedValue;
        } catch (e) {
            logger.error(e);
            return undefined;
        }
    }, [value, currency, locale]);

    return formattedNumber;
}

interface UseMinPriceString {
    locale: string;
    /**
     * MinPrice Object from FormattedEvent
     */
    price?: EventMinPrice;
    /**
     * Defaults to `From`
     */
    minPriceStringFormat?: 'starts' | 'from';
}

/**
 * Returns price string of format.
 *
 * Recommended for usage based on
 * https://eventbrite.atlassian.net/browse/EB-223485
 *
 * `From <localizedValue>`
 *
 * ie: `From $12.00`
 *
 * @param price EventMinPrice
 * @param locale string
 * @returns GenericLazyString
 */
export function useMinPriceString({
    locale,
    price,
    minPriceStringFormat = 'from',
}: UseMinPriceString): string | undefined {
    const currency = price?.currency || 'USD';
    const minPriceValue = price?.minPriceValue;

    const formattedMinPrice = useLocalizedPriceString({
        currency,
        value: minPriceValue,
        locale,
    });

    const stringOptionsMap = React.useMemo(() => {
        return {
            starts: gettext('Starts at %(formattedMinPrice)s', {
                formattedMinPrice,
            }).toString(),
            from: gettext('From %(formattedMinPrice)s', {
                formattedMinPrice,
            }).toString(),
        };
    }, [formattedMinPrice]);

    if (formattedMinPrice) {
        return stringOptionsMap[minPriceStringFormat];
    }
}

interface UsePriceRangeString {
    locale: string;
    minPrice?: EventMinPrice;
    maxPrice?: EventMaxPrice;
}

export function usePriceRangeString({
    locale,
    minPrice,
    maxPrice,
}: UsePriceRangeString): string | undefined {
    const currency = minPrice?.currency || 'USD';
    const minPriceValue = minPrice?.minPriceValue;
    const maxPriceValue = maxPrice?.maxPriceValue;

    const formattedMinNumber = useLocalizedPriceString({
        currency,
        value: minPriceValue,
        locale,
    });

    const formattedMaxNumber = useLocalizedPriceString({
        currency,
        value: maxPriceValue,
        locale,
    });

    if (formattedMinNumber === formattedMaxNumber) {
        return formattedMinNumber;
    }

    if (formattedMinNumber)
        return `${formattedMinNumber} - ${formattedMaxNumber}`;
}
