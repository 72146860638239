export enum ReportAdReason {
    adultSexual = 'adult-sexual',
    harmful = 'harmful',
    violenceOrExtremism = 'violence-or-extremism',
    electoralActivity = 'electoral-activity',
    illegalOrRegulated = 'illegal-or-regulated',
    spamOrPhising = 'spam-or-phising',
    copyrightAndTrademark = 'copyright',
    canceledOrPostponed = 'canceled-or-postponed',
}

export type ReportAdFormData = {
    reason: ReportAdReason | '';
    comment: string;
    email: string;
};

export const initialReportAdData: ReportAdFormData = {
    reason: '',
    comment: '',
    email: '',
};
