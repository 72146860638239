import { PlaceholderSvg1 } from '@eventbrite/eds-iconography';
import { Illustration } from '@eventbrite/eds-illustration';
import { gettext } from '@eventbrite/i18n';
import React, { CSSProperties } from 'react';
import './EventCardImage.scss';

export interface EventImageProps {
    src?: string;
    name: string;
    tokens?: EventImageTokens;
    loading?: 'eager' | 'lazy';
    shouldCompressImg?: boolean;
}

export interface EventImageTokens {
    '--image-aspect-ratio'?: string;
    '--image-aspect-ratio-mobile'?: string;
    '--image-width'?: string;
    '--image-width-mobile'?: string;
    '--image-background-color'?: string;
    '--image-border-radius'?: string;
}

//Hint to the browser of the actual
//image asset size for faster loading
const STATIC_IMAGE_HEIGHT = 256;
const STATIC_IMAGE_WIDTH = 512;

export const getEventImageAltContent = (eventName: string) =>
    gettext('%(eventName)s primary image', { eventName });

/**
 * `EventCardImage`
 *
 * Component used to render images within an EventCard. Use defined
 * tokens to configure for your particular usecase.
 *
 * Tokens:
 *
 * `--ImageAspectRatio`
 *
 * `--ImageAspectRatioMobile`
 *
 * `--ImageWidth`
 *
 * `--ImageWidthMobile`
 *
 * `--ImageBorderRadius`
 *
 * `--ImageBackgroundColor`
 *
 * @param props
 * @returns
 */
export const EventCardImage: React.FunctionComponent<EventImageProps> = (
    props,
) => {
    const alt = getEventImageAltContent(props.name);

    return (
        <div
            className="event-card-image__aspect-container"
            style={{ ...props.tokens } as CSSProperties}
        >
            {props.src ? (
                <img
                    height={STATIC_IMAGE_HEIGHT}
                    width={STATIC_IMAGE_WIDTH}
                    className="event-card-image"
                    src={props.src}
                    loading={props.loading}
                    alt={alt.toString()}
                    key={props.src}
                    //HTML Img Definition doesnt support fetchpriority but it
                    //is supported in chrome and edge with broader support coming
                    //https://caniuse.com/mdn-html_elements_img_fetchpriority
                    //eslint-disable-next-line
                    //@ts-ignore
                    fetchpriority={props.shouldCompressImg ? 'high' : undefined}
                />
            ) : (
                <div className="event-card-image">
                    <Illustration
                        type={<PlaceholderSvg1 />}
                        height="100%"
                        width="100%"
                    />
                </div>
            )}
        </div>
    );
};
