import { EBDomains } from '@eventbrite/feature-detection';
import { parseUrl } from 'url-lib';
import { EventCardLinkPropGetterConfig } from './types';

export const getEventCardLinkClickHandlers = (
    config: Pick<
        EventCardLinkPropGetterConfig,
        'statsigLocationString' | 'onClick'
    >,
) => {
    const clickHandlers = [];

    if (typeof config.onClick === 'function') {
        clickHandlers.push(config.onClick);
    }

    return clickHandlers;
};

const shouldReferTargetBlank = (url: string) => {
    const isRelative = url.startsWith('/');
    const { host } = parseUrl(url);
    const isEB =
        host.includes(EBDomains.EVENTBRITE) ||
        host.includes(EBDomains.EVBQA) ||
        host.includes(EBDomains.EVBSTAGE) ||
        host.includes(EBDomains.EVBDEV);

    return isRelative || isEB;
};

export const getLinkTargetRel = ({
    isTargetBlank,
    isNoFollow,
    url,
}: {
    isTargetBlank?: boolean;
    isNoFollow?: boolean;
    url: string;
}) => {
    const target = isTargetBlank ? '_blank' : '';
    const relNoFollow = isNoFollow ? 'nofollow' : '';
    const relTargetBlank = isTargetBlank
        ? shouldReferTargetBlank(url)
            ? 'noopener'
            : 'noopener noreferrer'
        : '';
    const rel = `${relTargetBlank} ${relNoFollow}`.trim();

    return { target, rel };
};
