import {
    ENROLLMENT_FN_MAP,
    GetEnrollmentStatus,
    GetStatsigEnrollmentResponse,
    IUseGetStatsigEnrollment,
    StatsigEnrollmentType,
    useServerEnrolledStatsigExperiment,
} from '@eventbrite/discover-utils';
import { useLayer } from '@eventbrite/statsig';
import { useEffect, useState } from 'react';

export { GetEnrollmentStatus, useServerEnrolledStatsigExperiment };

/**
 * **IMPORTANT**
 * This hook should be used only if your experiment has no
 * server side initialization in place and relies on
 * client based enrollment.
 *
 * If your experiment uses Stable ID this is the hook for you.
 *
 * ****
 *
 * According to statsig docs, the `getExperiment` and `getLayer`
 * lookups are a synchronous operation and so should
 * have little latency overhead.
 *
 * Until mounted on the client, the hook will return
 * `GetEnrollmentStatus.LOADING`. Allowing server side content to
 * render an appropriate result the variant is determined.
 *
 * ****
 *
 * @param name: Name of the layer or the experiment
 * @param paramName: Name of the param to retrieve the variant for
 * @returns GetStatsigEnrollmentResponse
 */
export function useClientStatsigEnrollment<T>({
    name,
    paramName,
    defaultValue,
    enabled = true,
    enrollmentType = StatsigEnrollmentType.EXPERIMENT,
}: IUseGetStatsigEnrollment<T>): GetStatsigEnrollmentResponse<T> {
    const [enrollmentVariantValue, setEnrollmentVariantValue] =
        useState<T>(defaultValue);

    const [status, setGetEnrollmentStatus] = useState<GetEnrollmentStatus>(
        !enabled ? GetEnrollmentStatus.DISABLED : GetEnrollmentStatus.LOADING,
    );

    useEffect(() => {
        if (enabled) {
            const getVariant = ENROLLMENT_FN_MAP[enrollmentType];
            const variant: T = getVariant(name, paramName, defaultValue);
            setEnrollmentVariantValue(variant);
            setGetEnrollmentStatus(GetEnrollmentStatus.SUCCESS);
        }
    }, [name, paramName, defaultValue, enabled, enrollmentType]);

    return {
        status,
        variant: enrollmentVariantValue,
    } as GetStatsigEnrollmentResponse<T>;
}

/**
 * **IMPORTANT**
 *
 * This hook assumes that the backend has passed along the
 * the Statsig bootstrap initialization config from the server
 * and StatsigSynchronousProvider is present in the tree.
 *
 * Recommended way to enroll Server enrolled users.
 *
 * Views that support this flow currently:
 * CategoryBrowse
 * Search
 *
 * @param param0
 * @returns
 */
export function useServerEnrolledStatsigLayer<T>({
    name,
    paramName,
    defaultValue,
}: IUseGetStatsigEnrollment<T>): GetStatsigEnrollmentResponse<T> {
    const response = useLayer<T>(name, paramName, defaultValue);
    const value = response.value as T;

    const result: GetStatsigEnrollmentResponse<T> = {
        status: response.isLoading
            ? GetEnrollmentStatus.LOADING
            : GetEnrollmentStatus.SUCCESS,
        variant: value,
    };

    return result;
}
