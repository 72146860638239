import * as React from 'react';

const HorizontalDotsChunkySvg = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        id="horizontal-dots-chunky_svg__eds-icon--horizontal-dots-chunky_svg"
        x={0}
        y={0}
        viewBox="0 0 24 24"
        xmlSpace="preserve"
        {...props}
    >
        <path
            id="horizontal-dots-chunky_svg__eds-icon--horizontal-dots-chunky_dot_2"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16 12c0-1.1.9-2 2-2s2 .9 2 2-.9 2-2 2-2-.9-2-2z"
        />
        <circle
            id="horizontal-dots-chunky_svg__eds-icon--horizontal-dots-chunky_dot_1"
            fillRule="evenodd"
            clipRule="evenodd"
            cx={12}
            cy={12}
            r={2}
        />
        <circle
            id="horizontal-dots-chunky_svg__eds-icon--horizontal-dots-chunky_dot"
            fillRule="evenodd"
            clipRule="evenodd"
            cx={6}
            cy={12}
            r={2}
        />
    </svg>
);

HorizontalDotsChunkySvg.displayName = 'HorizontalDotsChunkySvg';
export default HorizontalDotsChunkySvg;
