import classNames from 'classnames';
import React from 'react';
import { useEventCardTrackingContext } from '../EventCardTracking';
import './EventCardActions.scss';

interface EventCardActionTokens {
    '--EventCardActionsMargin'?: string;
    '--EventCardActionsPadding'?: string;
    '--EventCardActionsPosition'?: string;
    '--EventCardActionsTop'?: string;
    '--EventCardActionsBottom'?: string;
    '--EventCardActionsRight'?: string;
    '--EventCardActionsTransform'?: string;
    '--EventCardActionsGap'?: string;
}

interface EventCardActionsProps {
    className?: string;
    /**
     * Set of configurabe
     */
    tokens?: EventCardActionTokens;
    /**
     * Inserts the provided value under `actionStyle`
     * prop name to child components. Allows for
     * action wrapper to dictate expected appearance
     * to child actions that support the prop.
     */
    actionStyleOverride?: 'outline' | 'dark';
}

/**
 * Container element for rendering the event card actions. It supports
 * an option to inject `actionStyle` prop into children. This is
 * used in both EventCardSaveAction and EventCardShareAction as the
 * `style` prop passed into `IconButton`
 *
 * This allows for a single place to configure that value, and allow
 * for different event configurations to define their own visual
 * language for the actions.
 *
 * @param props
 * @returns
 */
export const EventCardActions: React.FunctionComponent<
    EventCardActionsProps
> = ({ className, children, actionStyleOverride, tokens }) => {
    const { heapDataAttributes } = useEventCardTrackingContext();

    if (!children) {
        return null;
    }

    let overwrittenChildren = null;

    if (actionStyleOverride) {
        overwrittenChildren = React.Children.map(children, (child) => {
            if (React.isValidElement(child)) {
                return React.cloneElement(child, {
                    ...child,
                    actionStyle: actionStyleOverride,
                    style: actionStyleOverride,
                });
            } else {
                return null;
            }
        });
    }

    return (
        <section
            className={classNames('event-card-actions', className)}
            style={tokens as React.CSSProperties}
            data-event-id={heapDataAttributes?.eventId}
            data-event-location={heapDataAttributes?.locationSlug}
            data-event-paid-status={heapDataAttributes?.paidStatus}
            data-event-category={heapDataAttributes?.category}
        >
            {overwrittenChildren ? overwrittenChildren : children}
        </section>
    );
};
