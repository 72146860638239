import React from 'react';
import {
    EventInteractions,
    useEventCardTrackingContext,
} from '../EventCardTracking';
import { EventCardLinkBase } from './EventCardLinkBase';
import { EventCardLinkProps } from './types';

export const EventCardLink: React.FunctionComponent<EventCardLinkProps> = (
    props,
) => {
    const { clickHandlers, ...remainingProps } = props;
    const { trackInteraction } = useEventCardTrackingContext();

    if (!trackInteraction) {
        throw new Error(
            'You are using `<EventCardLink>` which expects `EventCardTracking` to be defined as wrapping Context Provider. Please include `EventCardTracking` as a parent to this component or use `<EventCardLinkBase />`',
        );
    }

    const clickHandlersWithInternalTracking: Function[] = [
        ...clickHandlers,
        () => trackInteraction(EventInteractions.Click),
    ];

    return (
        <EventCardLinkBase
            {...remainingProps}
            clickHandlers={clickHandlersWithInternalTracking}
        >
            {props.children}
        </EventCardLinkBase>
    );
};
