import { EventInteractions } from '@eventbrite/ads';
import { HorizontalDotsChunky } from '@eventbrite/eds-iconography';
import { MoreActions } from '@eventbrite/eds-more-actions';
import { EVENT_INTERACTION, FormattedEvent } from '@eventbrite/event-renderer';
import { gettext } from '@eventbrite/i18n';
import React, { useRef, useState } from 'react';
import { ReportAdModal, ReportAdModalProps } from '../ReportAdModal';
import styles from './PromotedActionsMenuEventCard.module.scss';

export type PromotedActionsMenuEventCardProps = {
    event: FormattedEvent;
    onRemoveEvent: (eventId: string) => void;
    actionStyle?: string;
} & Pick<ReportAdModalProps, 'getTrackingContext'>;

export function PromotedActionsMenuEventCard({
    event,
    onRemoveEvent,
    getTrackingContext,
    actionStyle = 'dark',
}: PromotedActionsMenuEventCardProps) {
    const containerRef = useRef<HTMLSpanElement>(null);
    const [showReportModal, setReportModalVisibility] = useState(false);

    const actionHandler = (action: PromotedActions) => {
        switch (action) {
            case PromotedActions.hideAd:
                onRemoveEvent(event.id);
                sendActionMetrics(event, PromotedActions.hideAd, containerRef);
                break;
            case PromotedActions.reportAd:
                setReportModalVisibility(true);
                break;
            default:
                break;
        }
    };

    return (
        <span className={styles.container} ref={containerRef}>
            <MoreActions
                items={[
                    {
                        content: gettext('Hide this ad'),
                        value: PromotedActions.hideAd,
                    },
                    {
                        content: gettext('Report this ad'),
                        value: PromotedActions.reportAd,
                    },
                ]}
                style={actionStyle}
                iconType={<HorizontalDotsChunky />}
                title={gettext('Promoted event actions')}
                onAction={actionHandler}
            />
            {showReportModal && (
                <ReportAdModal
                    event={event}
                    handleClose={() => setReportModalVisibility(false)}
                    // Due to the modal being contained inside the event card, if we remove the event card from the DOM in the onSubmit handler
                    // the modal will be unmounted, hence, the onSuccess modal never appears
                    // This was not done as an initial implementation due to the report modal re-using the event interaction tracker and requiring to fire a DOM event
                    // FIXME: Migrate this to a new endpoint and move modal outside of the event card to avoid this issue
                    onSuccessClose={() => onRemoveEvent(event.id)}
                    getTrackingContext={getTrackingContext}
                />
            )}
        </span>
    );
}

export enum PromotedActions {
    hideAd = EventInteractions.HideAd,
    reportAd = EventInteractions.ReportAd,
}

export function sendActionMetrics(
    event: FormattedEvent,
    action: PromotedActions,
    element: React.RefObject<HTMLSpanElement>,
) {
    const interactionEvent = new CustomEvent(EVENT_INTERACTION, {
        detail: {
            id: event.id,
            action,
            event,
        },
        bubbles: true,
    });

    element.current?.dispatchEvent(interactionEvent);
}
