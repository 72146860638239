import { gettext } from '@eventbrite/i18n';
import React from 'react';
import { EventSpecialDiscountsData } from '../../types/events';
import { EventCardPromoCodeLabel } from './EventCardPromoCodeLabel';

export type EventCardBOGOLabelProps = {
    className?: string;
    specialDiscounts: EventSpecialDiscountsData | undefined;
};

export function EventCardBOGOLabel({
    className,
    specialDiscounts,
}: EventCardBOGOLabelProps) {
    if (!specialDiscounts?.hasBogoTickets) {
        return null;
    }

    return (
        <EventCardPromoCodeLabel className={className}>
            {gettext('2 for 1 deal')}
        </EventCardPromoCodeLabel>
    );
}
