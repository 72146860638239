import { gettext } from '@eventbrite/i18n';
import React from 'react';
import { formatUrl } from 'url-lib';
import { hasTicketsFromExternalProvider } from '../../transforms';
import { useEventCardTrackingContext } from '../EventCardTracking';
import './EventCardLink.scss';
import { EventCardLinkPropGetterConfig, EventCardLinkProps } from './types';
import { getEventCardLinkClickHandlers, getLinkTargetRel } from './utils';

export const getEventCardLinkProps = ({
    affCode: aff,
    className,
    label,
    statsigLocationString,
    onClick,
    isTargetBlank = true,
    event: { ticketsBy, ticketsUrl, url, name, isPromoted },
}: EventCardLinkPropGetterConfig): EventCardLinkProps => {
    const isExternalProvider = hasTicketsFromExternalProvider({ ticketsBy });
    const formattedListingsUrl = formatUrl(url, {
        aff,
    });
    const formattedUrl =
        isExternalProvider && isPromoted && ticketsUrl
            ? ticketsUrl
            : formattedListingsUrl;

    const { target, rel } = getLinkTargetRel({
        url: formattedUrl,
        isTargetBlank,
        isNoFollow: isExternalProvider,
    });
    const clickHandlers = getEventCardLinkClickHandlers({
        statsigLocationString,
        onClick,
    });

    return {
        href: formattedUrl,
        rel,
        target,
        clickHandlers: clickHandlers,
        className,
        'aria-label':
            label?.toString() ||
            gettext('View %(eventName)s', { eventName: name }).toString(),
    };
};

export const EventCardLinkBase: React.FunctionComponent<EventCardLinkProps> = ({
    children,
    ...props
}) => {
    const { clickHandlers, ...remainingProps } = props;

    const handleClick = (
        e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    ) => {
        clickHandlers.forEach((fn) => fn(e));
    };

    const { heapDataAttributes } = useEventCardTrackingContext();

    return (
        <a
            {...remainingProps}
            className={`event-card-link ${props.className || ''}`}
            onClick={(e) => handleClick(e)}
            data-event-id={heapDataAttributes?.eventId}
            data-event-location={heapDataAttributes?.locationSlug}
            data-event-paid-status={heapDataAttributes?.paidStatus}
            data-event-category={heapDataAttributes?.category}
        >
            {children}
        </a>
    );
};
