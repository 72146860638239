import { gettext } from '@eventbrite/i18n';
import { Typography, TypographyProps } from '@eventbrite/marmalade';
import React from 'react';
import { FormattedEvent } from '../../types/events';
import { EventCardTypographyLoading } from '../EventCardTypographyLoading';

type EventCardPricePropsFormattedEvent = Pick<
    FormattedEvent,
    'formattedPriceString' | 'isFree'
> & {
    isLoading?: boolean;
    hidePriceDescription?: boolean;
};

type ExtendedEventCardPriceProps = EventCardPricePropsFormattedEvent &
    Omit<TypographyProps, 'children' | 'style'>;

const FREE_COPY = gettext('Free');
const FALLBACK_COPY = gettext('Check ticket price on event');

export const EventCardPrice: React.FunctionComponent<
    ExtendedEventCardPriceProps
> = (props) => {
    const {
        formattedPriceString,
        isFree,
        hidePriceDescription,
        isLoading,
        ...typographyProps
    } = props;

    if (hidePriceDescription) {
        return null;
    }

    if (isFree) {
        return <Typography {...typographyProps}>{FREE_COPY}</Typography>;
    }

    if (formattedPriceString) {
        return (
            <Typography {...typographyProps}>{formattedPriceString}</Typography>
        );
    }

    if (isLoading) {
        return (
            <EventCardTypographyLoading variant={props.variant} width="120px" />
        );
    }

    return (
        <Typography {...typographyProps} color={'neutral-600'}>
            {FALLBACK_COPY}
        </Typography>
    );
};
