import {
    EventCardHomepageImageExperiment,
    EventCardImageExperimentValues,
    SearchEventCardLikesCountExperiment,
    SearchEventCardLikesCountExperimentValues,
} from '@eventbrite/discover-utils';
import { FormattedEvent, hasCarouselWidget } from '@eventbrite/event-renderer';
import { useMapExperiment as BaseUseMapExperiment } from '@eventbrite/search-utils';
import { useEnvContext } from '../../context';
import { useClientStatsigEnrollment } from '../../context/experiments';

export const useMapExperiment = () => {
    const envContext = useEnvContext();
    const isMobile = envContext?.env?.isMobile;
    return BaseUseMapExperiment(!!isMobile);
};

export const useMultiImageExperiment = (event: FormattedEvent) => {
    const { variant: eventCardImageExperiment } = useClientStatsigEnrollment({
        name: EventCardHomepageImageExperiment.name,
        paramName: EventCardHomepageImageExperiment.paramName,
        defaultValue: EventCardHomepageImageExperiment.values.control,
        enabled: event.isPromoted && hasCarouselWidget(event),
    });
    return eventCardImageExperiment === EventCardImageExperimentValues.multi;
};

export const useShowLikesExperiment = (isPromoted?: boolean) => {
    const { variant: showLikesEventCardExperiment } =
        useClientStatsigEnrollment({
            name: SearchEventCardLikesCountExperiment.name,
            paramName: SearchEventCardLikesCountExperiment.paramName,
            defaultValue: SearchEventCardLikesCountExperiment.values.control,
            enabled: isPromoted,
        });

    return (
        showLikesEventCardExperiment ===
        SearchEventCardLikesCountExperimentValues.showLikes
    );
};
