import { HAS_WINDOW } from '@eventbrite/feature-detection';
import { formatUrl } from 'url-lib';
import { EventCardShareOptionsProps } from '../../types/events';

type GetShareOptions = (config: IGetShareOptions) => EventCardShareOptionsProps;

export interface IGetShareOptions {
    id: string;
    url: string;
    name: string;
    affCode: string;

    twitterHandle?: string;
    facebookOptions?: {
        appId: string;
        version: string;
        xfbml: boolean;
        page?: '';
        locale?: '';
    };
    utmOptions?: {
        'utm-campaign'?: string;
        'utm-content'?: string;
        'utm-medium'?: string;
        'utm-source'?: string;
        'utm-term'?: string;
        'utm-share-source'?: string;
    };
}

export const getShareOptions: GetShareOptions = (config) => {
    return {
        eventUrl: formatUrl(config.url, { aff: config.affCode }),
        eventId: config.id,
        eventName: config.name,
        twitterHandle: '',
        serverUrl: '',
        isMobile: false,
        facebookOptions: {
            appId: config?.facebookOptions?.appId || '28218816837',
            version: config?.facebookOptions?.version || 'v2.8',
            xfbml:
                config?.facebookOptions?.xfbml !== undefined
                    ? config?.facebookOptions?.xfbml
                    : true,
            locale: config?.facebookOptions?.locale || 'en_US',
            page: '',
        },
        utmOptions: {
            'utm-campaign': config?.utmOptions?.['utm-campaign'] || '',
            'utm-content': config?.utmOptions?.['utm-content'] || '',
            'utm-medium': config?.utmOptions?.['utm-medium'] || '',
            'utm-source': config?.utmOptions?.['utm-source'],
            'utm-term': config?.utmOptions?.['utm-term'] || '',
            'utm-share-source': config?.utmOptions?.['utm-share-source'],
        },
    };
};

interface ShareWithSystemShare {
    eventName: string;
    eventUrl: string;
    onFail?: () => void;
    onSuccess?: () => void;
    onError?: () => void;
}
/**
 *
 * @param config ShareWithSysemShare
 *
 * Attempt to share provided event with the system share sheet. Provides callbacks
 * if not supported or errors during the flow.
 *
 * Options:
 *
 * `config.onSuccess`: Share via system share sheet successful
 *
 * `config.onFail`: Share sheet not supported by current environment
 *
 * `config.onError`: Share sheet supported but error occurred during share
 */
export const shareWithSystemShare = async (config: ShareWithSystemShare) => {
    const eventName = config.eventName;
    const eventUrl = config.eventUrl;

    if (
        HAS_WINDOW &&
        window.navigator &&
        typeof window.navigator.share === 'function'
    ) {
        window.navigator
            .share({
                title: eventName,
                text: eventUrl,
                url: eventUrl,
            })
            .then(
                () => {
                    config.onSuccess?.();
                },
                () => {
                    config.onError?.();
                },
            );
    } else {
        config.onFail?.();
    }
};
