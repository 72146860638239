import React from 'react';

/**
 * Basic component that returns a span. To be used in conjunction with
 * EventCardDetails to provide additional units of space between children
 * in alignment with the spacing value configured by Stack rendered by
 * EventCardDetails
 */
export const EventCardDetailsSpacer: React.FunctionComponent = () => {
    return <span />;
};
